import './index.scss'
import { useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

export default function Channel() {
  const navigate = useNavigate()
  const toForm = (e) => {
    if(e.target.nodeName === 'A' || e.target.nodeName === 'svg' || e.target.nodeName === 'path') return
    navigate('/form', {
        state: {
            isClient: false
        }
    })
  }
  return (
    <div className='channel'>
        <Header></Header>
        <img src={require('./imgs/banner.jpg')} alt="" className='banner' onClick={toForm} />
        <img src={require('./imgs/banner-s.png')} alt="" className='banner-s' onClick={toForm} />
        <Footer></Footer>
    </div>
  )
}
