import React, {useState} from 'react'
import { NavLink } from 'react-router-dom'
import style from './index.module.scss'
import { CloseOutlined, UnorderedListOutlined } from '@ant-design/icons'


export default function Header() {
    const [flag, setFlag] = useState(false)  //控制显示隐藏下拉框
    const computedClassName = ({isActive}) => {
        return isActive ? style.active + ' ' + style.nav : style.nav
    }


    
    
    const open = () => {
        setFlag(true)
    }
    const close = () => {
        setFlag(false)
    }

    return (
        <div className={style.header}>
            <div className={style.content}>
                <img src={require('./imgs/logo.png')} alt="" />
                <div className={style.navs}>
                    <NavLink to='/home' className={computedClassName}>首页</NavLink>
                    <NavLink to='/case' className={computedClassName}>经典案例</NavLink>
                    <NavLink to='/channel' className={computedClassName}>渠道合作</NavLink>
                    <NavLink to='/news/newList' className={computedClassName}>新闻资讯</NavLink>
                    <NavLink to='/aboutUs' className={computedClassName}>关于我们</NavLink>
                    <div className={style.btn}>免费咨询</div>
                </div>
            </div>

            <div className={style.s_content}>
                <div className={style.top}>
                    <div className={style.s_logo}>
                        <img src={require('./imgs/logo.png')} alt="" />
                    </div>
                    {
                        flag ? <CloseOutlined onClick={close} className={style.close} /> : <UnorderedListOutlined onClick={open} className={style.open} />
                    }
                </div>
                {
                    flag ? <div className={style.select}>
                                <NavLink to='/home'>首页</NavLink>
                                <NavLink to='/case'>经典案例</NavLink>
                                <NavLink to='/channel'>渠道合作</NavLink>
                                <NavLink to='/news/newList'>新闻资讯</NavLink>
                                <NavLink to='/aboutUs'>关于我们</NavLink>
                            </div> : ''
                }
            </div>
        </div>
    )
}
