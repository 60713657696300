import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import './details.scss'
import api from '../../api/api'

export default function Details() {
  const navigate = useNavigate()
  const { id } = useLocation().state
  const [newId, setNewId] = useState(id)
  const [data, setData] = useState({
    pre: {},
    last: {}
  })
  useEffect(() => {
    api.newInfo({
      id: newId
    }).then(res=> {
      setData(data => ({...data, ...res.data}))
    })
  }, [newId]) // eslint-disable-line react-hooks/exhaustive-deps

  const toJump = (num) => {
    setNewId(num)
  }

  const toForm = (e) => {
    if(e.target.nodeName === 'A' || e.target.nodeName === 'svg' || e.target.nodeName === 'path') return
    navigate('/form', {
        state: {
            isClient: true
        }
    })
  }

  const isShow = () => {
    if(!data.pre) return <p className='btn' onClick={v => toJump(data.last.id)}>下一篇：{data.last.title}</p>
    if(!data.last) return <p className='btn' onClick={v => toJump(data.pre.id)}>上一篇：{data.pre.title}</p>
    if(data.pre && data.last) {
      return (
        <>
          <p className='btn' onClick={v => toJump(data.pre.id)}>上一篇：{data.pre.title}</p>
          <p className='btn' onClick={v => toJump(data.last.id)}>下一篇：{data.last.title}</p>
        </>
      )
    }
  }

  return (
    <div className='details'>
        <div className="banner" onClick={toForm}>
            <Header></Header>
            <img src={require('./imgs/banner.png')} alt="" className='banner-pic' />
        </div>
        <div className="title">{data.title}</div>
        <div className="date">{data.create_time}</div>
        <div className="content dcss" dangerouslySetInnerHTML={{__html: data.remark}}></div>
        {
          isShow()
        }
        <Footer></Footer>
    </div>
  )
}
