import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import './index.scss'
import { message } from 'antd';
import { PHONE } from '../../assect/js/regex'
import api from '../../api/api'

let timer = null
export default function Form() {

    const { isClient } = useLocation().state // 是否为客户 true: 客户  false: 公司

    const [personal, setPersonal] = useState({
        phone: 0, // 个人联系电话
        code: 0   // 验证码
    })

    const [company, setCompany] = useState({
        companyName: '', //公司名称
        name: '',  // 负责人姓名
        phone: '',  // 负责人电话
        need: ''  // 公司需求
    })



    const [num, setNum] = useState(60) // 验证码读秒
    const [flag, setFlag] = useState(true) // 验证码样式
    
    useEffect(()=> {
        if(num <= 1) {
            clearInterval(timer)
            setNum(60)
            setFlag(true)
        }
    }, [num]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=> {
        return () => {
            clearInterval(timer)
        }
    }, [])
    // 获取验证码
    const getCode = () => {
        if(!PHONE.test(personal.phone)) return message.error('手机号格式不正确！')
        api.sendCode({
            mobile: personal.phone
        }).then(res=> {
            if(res === 1) {
                message.success('发送成功!');
                setFlag(false)
                timer = setInterval(() => {
                    setNum(n => n - 1)
                }, 1000);
                return;
            }else if(res === 7){
                message.error('亲，验证码3分钟内有效，有效期不允许重复发送');
                return;
            }else if(res === 3){
                message.error('手机号为空！');
                return;
            }else if(res === 4){
                message.error('该手机号已申请过了！');
                return;
            }else{
                message.error('验证码发送失败！');
                return;
            }
        })
    }
    // 客户提交
    const submit1 = () => {
        if(!personal.code) return message.error('验证码不能为空！')
        if(!PHONE.test(personal.phone)) return message.error('手机号格式不正确！')
        api.submit({
            truename: '游客',
            mobile: personal.phone,
            msgcode: personal.code,
            source: '微易融官网',
        }).then(res=> {
            if(res.code === 1) {
                message.success('申请成功！');
            } else {
                message.error(res.msg);
            }
        })
    }
    // 企业提交
    const submit2 = () => {
        if(!company.companyName) return message.error('公司名称不能为空！')
        if(!company.name) return message.error('负责人姓名不能为空！')
        if(!PHONE.test(company.phone)) return message.error('手机号格式不正确！')
        api.companySubmit({
            company_name: company.companyName,
            fzr_name: company.name,
            mobile: company.phone,
            need: company.need,
        }).then(res=>{
            if(res.code === 1) {
                message.success('提交成功！');
            } else {
                message.error(res.msg);
            }
        })
    }

    // 默认展示
    const show = () => {
        if(isClient) {
            return (
                <div className="content">
                    <div className="title">
                        <img src={require('./imgs/logo-b.png')} alt="" />
                        <p>让贷款变的更简单</p>
                    </div>
                    <div className="ipts">
                        <h3>VIP咨询通道</h3>
                        <p>手机号码</p>
                        <div className="ipt">
                            <input type="text" onInput={e => setPersonal(p => ({...p, phone: e.target.value}))} placeholder="请输入手机号" />
                        </div>
                        <p>短信验证码</p>
                        <div className="ipt">
                            <input type="text" onInput={e => setPersonal(p => ({...p, code: e.target.value}))} placeholder="请输入验证码" />
                            {
                                flag ? 
                                <span className='getCode' onClick={getCode}>获取验证码</span> 
                                : <span className='getCode no'>重新获取({ num })</span>
                            }
                        </div>
                        <div className="btn" onClick={submit1}>立即咨询</div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="content content-q">
                    <div className="title">
                        <img src={require('./imgs/logo-b.png')} alt="" />
                        <p>让商业变得更智慧</p>
                    </div>
                    <div className="ipts">
                        <h3>渠道合作</h3>
                        <p>公司名称</p>
                        <div className="ipt">
                            <input type="text" onInput={e => setCompany(p => ({...p, companyName: e.target.value}))} placeholder="请输入您的公司名称" />
                        </div>
                        <p>负责人姓名</p>
                        <div className="ipt">
                            <input type="text" onInput={e => setCompany(p => ({...p, name: e.target.value}))} placeholder="请输入负责人姓名" />
                        </div>
                        <p>联系方式</p>
                        <div className="ipt">
                            <input type="text" onInput={e => setCompany(p => ({...p, phone: e.target.value}))} placeholder="请输入负责人的手机号码" />
                        </div>
                        <p>需求</p>
                        <div className="ipt">
                            <input type="text" onInput={e => setCompany(p => ({...p, need: e.target.value}))} placeholder="请输入您的需求" />
                        </div>
                        <div className="btn" onClick={submit2}>提交</div>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className='form'>
            {
                show()
            }
        </div>
    )
}
