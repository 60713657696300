import http from './http'
const { Get, Post } = http

let baseURL_news = ''
let baseURL_submit = ''

// 根据环境变量区分接口的默认地址
switch (process.env.NODE_ENV) {
    case 'production':
        baseURL_news = 'https://wzadmin.liushusci.com'
        baseURL_submit = 'https://api.liushusci.com'
        break
    case 'test':
        baseURL_news = '/api_news'
        baseURL_submit = '/api_submit'
        break
    case 'development':
        baseURL_news = '/api_news'
        baseURL_submit = '/api_submit'
        break
    default :
        baseURL_news = '/api_news'
        baseURL_submit = '/api_submit'
        break
}

const api = {
    newsList(params) { return Get(`${baseURL_news}/api/api/newList`, params) },
    newInfo(params) { return Get(`${baseURL_news}/api/api/newInfo`, params) },
    sendCode (params) { return Post(`${baseURL_submit}/sendsms/get_sms_code.html`, params) },
    submit (params) { return Post(`${baseURL_submit}/api/wzapi/sgdata`, params) },
    companySubmit (params) { return Post(`${baseURL_submit}/api/wzapi/ajax_channel_cooperation`, params)}
}

export default api