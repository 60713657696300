import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import api from '../../api/api'
import './newList.scss'
import { Pagination } from 'antd';

export default function NewList() {
    const [ top, setTop ] = useState([])
    const [ newList, setNewList ] = useState([])
    const [current, setCurrent] = useState(1)
    const [total, setTotal] = useState(6)
    const navigate = useNavigate()
    
    useEffect(()=> {
        getNewList()
    }, [current]) // eslint-disable-line react-hooks/exhaustive-deps

    // 获取新闻列表
    const getNewList = () => {
        api.newsList({
            page: current,
            num: 6
        }).then(res => {
            const dataList = res.data.data
            setTop(dataList.slice(0, 3))
            setNewList(dataList.slice(3))
            setTotal(res.data.total)
        })
    }

    // 切换分页
    const onChangePage = (page) => {
        setCurrent(page)
    }

    // 跳转详情页
    const toDetails = (num) => {
        navigate('/news/details', {
            state: {
                id: num
            }
        })
    }
    
    const toForm = (e) => {
        if(e.target.nodeName === 'A' || e.target.nodeName === 'svg' || e.target.nodeName === 'path') return
        navigate('/form', {
            state: {
                isClient: true
            }
        })
    }
    return (
        <div className='newList'>
            <div className="banner" onClick={toForm}>
                <Header></Header>
                <img src={require('./imgs/banner.png')} alt="" className='banner-pic' />
            </div>
            <div className="top">
                <div className="bg">
                    <ul>
                        {
                            top.map(item=> {
                                return (
                                    <li key={item.id} onClick={v => toDetails(item.id)}>
                                        <img src={item.images} alt="" />
                                        <h2>{item.title}</h2>
                                        <p>{item.describe}</p>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
            <div className="newList2">
                <div className="left"></div>
                <div className="right"></div>
                <div className="bg">
                    <ul className='beautify-scroll'>
                        {
                            newList.map(item=> {
                                return (
                                    <li key={item.id}>
                                        <div className="text">
                                            <h2>{item.title}</h2>
                                            <p>{item.describe}</p>
                                            <span>{item.update_time}</span>
                                        </div>
                                        <div className="yuan" onClick={v => toDetails(item.id)}>
                                            <img src={require('../../assect/imgs/arrow_right.png')} alt="" />
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
            <div className="pages">
                <Pagination defaultCurrent={current} total={total} onChange={onChangePage}/>
            </div>
            <Footer></Footer>
        </div>
    )
}