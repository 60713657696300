import Home from '../pages/home'
import Case from '../pages/case'
import AboutUs from '../pages/aboutUs'
import Channel from '../pages/channel'
import News from '../pages/news'
import NewList from '../pages/news/newList'
import Details from '../pages/news/details'
import Form from '../pages/form'
import { Navigate } from 'react-router-dom'
const routes = [
    {
        path: '/',
        element: <Navigate to="/home" />
    },
    {
        path: '/home',
        element: <Home />
    },
    {
        path: '/case',
        element: <Case />
    },
    {
        path: '/aboutUs',
        element: <AboutUs />
    },
    {
        path: '/channel',
        element: <Channel />
    },
    {
        path: '/news',
        element: <News />,
        // element: <Navigate to="/news/newList" />,
        children: [
            {
                path: 'newList',
                element: <NewList />
            },
            {
                path: 'details',
                element: <Details />
            }
        ]
    },
    {
        path: '/form',
        element: <Form />
    },
]
export default routes